import { observable, reaction } from 'mobx';

export const progressBarStore = observable({
	progress: 0
});

reaction(
	() => progressBarStore.progress,
	(progress, reaction) => reaction.dispose()
);
