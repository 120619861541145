import React, { useEffect, useState, useRef } from 'react';

import { sleep } from '../../core/helpers';

export const RetryButton = ({ onRetry }) => {
	const [className, setClassName] = useState('');
	let mountedRef = useRef(false);
	useEffect(() => {
		mountedRef.current = true;
		return () => (mountedRef.current = false);
		// eslint-disable-next-line
	}, []);
	const handleOnClick = async () => {
		if (onRetry) {
			setClassName('glyphicon-refresh-animate');
			await Promise.all([onRetry(), sleep(500)]);
			if (mountedRef.current === true) {
				setClassName('');
			}
		}
	};

	const iconClasses = `glyphicon glyphicon-refresh ${className}`.trim();

	return (
		<label className='looking-songs-label retry-label' onClick={handleOnClick}>
			<span className={iconClasses}></span>
			Reintentar
		</label>
	);
};
