import React,{useState,useEffect} from 'react';

import { PlayButton } from '../PlayButton';
//TODO Volume buttons enable AudioSingleton
import { AudioSingleton } from '../../core/AudioSingleton';

import { observer } from 'mobx-react';

import './style.css';



export const PlayBar = observer(
	({
		playBarStore,
		playButtonIsVisible,
		onPlay,
		buttonDisabled = false,
		isSpot = false
	}) => {
		const [volRange, setvolRange] = useState(1);
		const [oldState, setOldState] = useState(1);
		const [volState, setVolState] = useState(false);
		const [icon, setIcon] = useState("glyphicon glyphicon-volume-up");
		const [margin, setMargin] = useState(12);
		
		console.log(margin);
		const handleChange = ({target}) => {
			console.log(target.value);
			setvolRange(target.value);
			setOldState(target.value);
			if (target.value <= 0.5) {
				setIcon("glyphicon glyphicon-volume-down")
			}
			if (target.value >= 0.6) {
				setIcon("glyphicon glyphicon-volume-up")
			}
			
			AudioSingleton.setVolume(target.value);
		}

		const clickMute = () => {
			setVolState(!volState);
			console.log(volState);
			if (volState === false) {
				setvolRange(0);
				setIcon("glyphicon glyphicon-volume-off")
			}else{
				setvolRange(oldState);
				if (oldState >= 0.6) {
					setIcon("glyphicon glyphicon-volume-up")
				} else {
					setIcon("glyphicon glyphicon-volume-down")
				}
			}
			AudioSingleton.mute();
		}

		useEffect(() => {
			const sUsrAg = navigator.userAgent;
			if (sUsrAg.indexOf("Firefox") > -1) {
				setMargin(0);
			}
		}, [])
		
		//TODO Volume buttons add style
		return (
			<>
			<div className='test'>
				
				<PlayButton
					isSpot={isSpot}
					isPlaying={playBarStore.isPlaying}
					visible={playButtonIsVisible}
					disabled={buttonDisabled}
					onClick={onPlay}
				/>
			
			</div>
			<div
				id='volumen'
				style={{color:'white',cursor:'pointer',position:'absolute',right:'30%',marginTop:'10px',fontSize:'20px'}}
			>
				<i 
					className={icon}
					onClick={clickMute}
				>
				</i>
				<input 
					type="range" 
					min={0} 
					max={1}
					step={0.02}  
					value={volRange} 
					onChange={handleChange}
					style={{marginTop: margin}}
				/>
			</div>
			</>
		);
	}
);
