import axios from 'axios';

import get from 'lodash/get';

import { fetchItem } from './storage';

import { songsAPIUrl } from '../config';

export async function startSong(songId, type) {

	try {
		const storeId = get(fetchItem('STORE_INFO'), 'storeId', '');

		const token = fetchItem('APP_TOKEN_SESSION');
  
		if (!songId || !token) {
			console.log('No token or songId provided');
			return;
		}
		
		const response = await axios({
			method: 'POST',
			url: `${songsAPIUrl}/api/v4/yumix`,
			headers: {
				'x-access-token': token
			},
			data: {
				storeId,
				songId,
				type,
				actionType: 'starting'
			}
		});

		// console.log('Yumix start song notification: ', {
		// 	storeId,
		// 	songId,
		// 	type,
		// 	actionType: 'starting'
		// });

		// console.log('Yumix response: ', JSON.stringify(response.data));
		return response.data;
	} catch (err) {
		console.log(err);
	}
}


export async function endSong(songId, type) {

	try {
		const storeId = get(fetchItem('STORE_INFO'), 'storeId', '');

		const token = fetchItem('APP_TOKEN_SESSION');
  
		if (!token) {
			console.log('No token provided');
			return;
		}
		songId = songId ? songId : '0';

		const response = await axios({
			method: 'POST',
			url: `${songsAPIUrl}/api/v4/yumix`,
			headers: {
				'x-access-token': token
			},
			data: {
				storeId,
				songId,
				type,
				actionType: 'ending' 
			}
		});

		// console.log('Yumix end song notification: ', {
		// 	storeId,
		// 	songId,
		// 	type,
		// 	actionType: 'starting'
		// });

		// console.log('Yumix response: ', JSON.stringify(response.data));
		return response.data;
	} catch (err) {
		console.log(err);
	}
}
