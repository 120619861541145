import React from 'react';

import { getBase64Image } from '../../core/helpers';

let defaultLogo = null;

export function getDefaultLogo(url) {
	if (defaultLogo) return defaultLogo;
	getBase64Image(url).then(logo => {
		defaultLogo = logo;
	});
	return url;
}

const Logo = ({ src, className = '' }) => {
	const classes = ['default-logo', className].join(' ').trim();
	return (
		<img className={classes} src={src} alt='Default' width={200} height={200} />
	);
};

export const DefaultLogo = React.memo(Logo, (prevProps, nextProps) => {
	if (prevProps.src === nextProps.src) {
		return true;
	}
	return false;
});
