const audioInstance = new Audio();
let song = null;

export class AudioSingleton {
	static bindEvents() {
		audioInstance.addEventListener(
			'timeupdate',
			() => song && song.onTimeUpdate(),
			false
		);
		audioInstance.addEventListener('ended', () => song && song.onEnd(), false);
		audioInstance.addEventListener(
			'error',
			() => song && song.onError(),
			false
		);
		audioInstance.addEventListener(
			'pause',
			() => {
				song && song.onPause();
			},
			false
		);
		audioInstance.addEventListener(
			'play',
			() => {
				song && song.onPlay();
			},
			false
		);
	}
	static setVolume(val) {
		// console.log(val);
		audioInstance.volume = val;
	}
	static upVolume() {
		if (audioInstance.volume < 0.9) {
			audioInstance.volume = audioInstance.volume + 0.1;
		} else {
			audioInstance.volume = 1;
		}
	}
	static downVolume() {
		if (audioInstance.volume > 0.1) {
			audioInstance.volume = audioInstance.volume - 0.1;
		} else {
			audioInstance.volume = 0;
		}
	}
	static mute() {
		audioInstance.muted = !audioInstance.muted;
	}
	static setSong(newSong) {
		song = newSong;
	}
	static getInstance() {
		return audioInstance;
	}
}
