import React, { useEffect, Fragment } from 'react';

import ReactDOM from 'react-dom';

import { observer } from 'mobx-react';

import axios from 'axios';

import get from 'lodash/get';

import * as serviceWorker from './serviceWorker';

import { appStore } from './stores/app.store';

import { playBarStore } from './stores/play.bar.store';

import { LoginForm } from './components/Login';

import { App } from './App';

import BlockUi from 'react-block-ui';

import 'react-block-ui/style.css';

import './styles.css';

import 'bootstrap-css-only';

import { ToastProvider } from 'react-toast-notifications';

import { Spinner } from './components/Spinner';

import packageJSON from '../package.json';

import { fetchItem } from './core/storage';

import { getComputerId } from './config';

axios.interceptors.request.use((config) => {
	const storeInfo = fetchItem('STORE_INFO');
	const computerId = getComputerId();
	const storeId = get(storeInfo, 'storeId', '');
	const storeName = get(storeInfo, 'storeName', '');
	config.headers['player-version'] = packageJSON.version;
	config.headers['player-name'] = packageJSON.productName;
	config.headers['device-id'] = appStore.deviceId;
	config.headers['store-id'] = storeId;
	config.headers['store-name'] = storeName;
	config.headers['computer-id'] = computerId;
	return config;
}, (error) => Promise.reject(error));

try {
	if (window.ipcRenderer) {
		const processType = window && window.process && window.process.type;
		appStore.deviceId = processType === 'renderer' ? 'desktop' : 'web';
		window.ipcRenderer.on('auto-updater-error', (e, message) => {
			console.log('auto-updater-error', message);
			appStore.newUpdateAvailable = false;
			appStore.newUpdateDownloaded = false;
		});
		window.ipcRenderer.on('auto-updater-checking-for-update', (e, message) => {
			console.log('auto-updater-checking-for-update', message);
			appStore.newUpdateAvailable = false;
			appStore.newUpdateDownloaded = false;
		});
		window.ipcRenderer.on('auto-updater-update-available', (e, message) => {
			console.log('auto-updater-update-available', message);
			appStore.newUpdateAvailable = true;
			appStore.newUpdateDownloaded = false;
		});
		window.ipcRenderer.on('auto-updater-update-not-available', (e, message) => {
			console.log('auto-updater-update-not-available', message);
			appStore.newUpdateAvailable = false;
			appStore.newUpdateDownloaded = false;
		});
		window.ipcRenderer.on('auto-updater-update-downloaded', (e, message) => {
			console.log('auto-updater-update-downloaded', message);
			appStore.newUpdateAvailable = false;
			appStore.newUpdateDownloaded = true;
		});
	}
} catch (err) {
	console.log(err);
}

const BlockWrapper = observer(() => {
	useEffect(() => {
		if (appStore.newUpdateAvailable || appStore.newUpdateDownloaded) {
			document.getElementsByTagName('body')[0].style.height = '100%';
		}
		return () => {
			document.getElementsByTagName('body')[0].style.height = 'auto';
		};
	}, []);

	const handleInstall = () => {
		if (window.ipcRenderer) {
			window.ipcRenderer.send('trigger-install', true);
		}
	};

	const shouldShowModal =
		appStore.newUpdateAvailable || appStore.newUpdateDownloaded;

	if (shouldShowModal === false) {
		return null;
	}

	const resolveContentToRender = () => {
		if (appStore.newUpdateAvailable) {
			return (
				<div className='update-message-content'>
					<h1>Descargando Actualización.</h1>
					<p>
						Descargando datos, esto puede tomar unos minutos dependiendo de su
						velocidad de internet.
					</p>
					<Spinner />
				</div>
			);
		}
		if (appStore.newUpdateDownloaded) {
			return (
				<div className='update-message-content'>
					<h1>Nueva actualización.</h1>
					<p>
						Hay una nueva actualización necesaria para su player, presione
						"Actualizar" para poder continuar.
					</p>
					<button className='form-button' onClick={handleInstall}>
						Actualizar
					</button>
				</div>
			);
		}
	};

	return (
		<BlockUi tag='div' blocking={shouldShowModal}>
			{resolveContentToRender()}
		</BlockUi>
	);
});

const SetupApp = observer(() => {
	if (appStore.sessionToken) {
		return (
			<Fragment>
				<BlockWrapper />
				<App appStore={appStore} playBarStore={playBarStore} />
			</Fragment>
		);
	}
	return (
		<ToastProvider>
			<BlockWrapper />
			<LoginForm appStore={appStore} />
		</ToastProvider>
	);
});

ReactDOM.render(<SetupApp />, document.getElementById('root'));

serviceWorker.unregister();
