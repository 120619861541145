import React, { useEffect } from 'react';
import get from 'lodash/get';
import { observer } from 'mobx-react';
import { Detector } from 'react-detect-offline';

import { AlbumInfo } from './components/AlbumInfo';
import { PlayBar } from './components/PlayBar';
import { ProgressBar } from './components/ProgressBar';
import { DisclaimerLabel } from './components/DisclaimerLabel';

import { SplashScreen } from './components/SplashScreen';

import { OfflineSplashScreen } from './components/OfflineSplashScreen';

import { playBarStore } from './stores/play.bar.store';

import { progressBarStore } from './stores/progress.bar.store';

import { Player } from './core/player';
import { deleteItem } from './core/storage';
import { playerStatusTrack, getSpotsSegments } from './core/player.service';

import logoBanner from './img/logo-banner.png';

import { songsAPIUrl, getTrackingThreshold } from './config';

let player = null;

let intervalId = null;

let bgTimeout = null;

const AppContent = observer(({ online, appStore }) => {
	useEffect(() => {
		appStore.isOnline = online;
		player = new Player({
			appStore,
			progressBarStore,
			playBarStore,
			initialQueueSize: 1
		});
		player.start().then(() => {
			console.log('Started');
		});
		intervalId = setInterval(async () => {
			const currentStatus = player.getCurrentStatus();
			console.log('Current Player Status:', currentStatus);
			const playerStateResponse = await playerStatusTrack(currentStatus);
			if (playerStateResponse && playerStateResponse.sessionExpired === true) {
				console.log('Should Expire the session');
				localStorage.clear();
			}
		}, getTrackingThreshold());
		return () => {
			clearInterval(intervalId);
		};
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		const body = document.getElementById('body-element');
		if (body) {
			if (appStore.currentSong && appStore.currentSong.image && appStore.loadingJukeBox === false) {
				bgTimeout = setTimeout(() => {
					if (appStore.currentSong && appStore.currentSong.image && appStore.loadingJukeBox === false) {
						const src = appStore.currentSong.image;
						const newSrc = `url("${src}")`;
						if (newSrc !== body.style.backgroundImage) {
							body.style.backgroundImage = newSrc;
						}
						clearTimeout(bgTimeout);
						bgTimeout = null;
					}
				}, 400);
			} else {
				body.style.backgroundImage = '';
			}
		}
	}, [appStore, appStore.currentSong, appStore.loadingJukeBox]);

	const src = get(appStore, 'currentSong.image', '');

	const handleLogout = () => {
		appStore.forceLogout = true;
		appStore.sessionToken = null;
		appStore.storeName = '';
		deleteItem('APP_TOKEN_SESSION');
		deleteItem('STORE_INFO');
	};

	const renderMiniCover = () => {
		if (!appStore.currentSong || appStore.loadingJukeBox) {
			return null;
		}
		return (
			<div className='left-image-song'>
				<div className='cover-mini-container'>
					<img
						id='album-mini-cover'
						alt='album-mini-cover'
						style={{ borderRadius: 0 }}
						src={src}
						width={64}
						height={64}
					/>
				</div>
			</div>
		);
	};

	const renderCurrentTime = () => {
		if (playBarStore.isPlaying === false) {
			return null;
		}
		return <span className='current-time'>{playBarStore.currentTime}</span>;
	};

	const renderPlayerContent = () => {
		if (appStore.offLineState === true) {
			return <OfflineSplashScreen />;
		}
		if (!appStore.isReady || appStore.loadingJukeBox || appStore.errorStarting === true) {
			return (
				<SplashScreen
					errorStarting={appStore.errorStarting}
					onRetry={async () => {
						await player.start();
					}}
				/>
			);
		}
		return (
			<>
				<AlbumInfo
					storeName={appStore.storeName}
					songInfo={appStore.currentSong}
					isPlaying={playBarStore.isPlaying}
					waitingForPlay={appStore.waitingForPlay}
					loadingJukeBox={appStore.loadingJukeBox}
				/>
				<DisclaimerLabel
					isOnline={appStore.isOnline}
					isSlowConnection={appStore.isSlowConnection}
				/>
			</>
		);
	};

	const isSpot = get(appStore, 'currentSong.isSpot', false);

	const overlayClass = appStore.currentSong && !appStore.loadingJukeBox ? ' overlay-bg' : '';

	return (
		<React.Fragment>
			<nav className='navbar navbar-expand-lg navbar-dark primary-color menu'>
				<div className='logo-container'>
					<img src={logoBanner} className='logo-content-small' alt='logo' />
				</div>
				<div className='logout-button'>
					<span
						onClick={handleLogout}
						className='glyphicon glyphicon-off'
					></span>
				</div>
			</nav>
			<div id='body-element' className='body'>
				<div
					className={`body-content${overlayClass}`}
				>
					<div className='player-music-content'>{renderPlayerContent()}</div>
				</div>
				<div className='player-bar-menu'>
					<ProgressBar progressBarStore={progressBarStore} />
					{renderMiniCover()}
					<PlayBar
						isSpot={isSpot}
						playButtonIsVisible
						buttonDisabled={!appStore.isReady || appStore.loadingJukeBox}
						playBarStore={playBarStore}
						onPlay={async () => {
							// is a spot and the user click on play
							if (
								!appStore.isReady ||
								appStore.errorStarting === true ||
								isSpot ||
								appStore.waitingForPlay
							) {
								return;
							}
							playBarStore.isPlaying = await player.play();
							if (appStore.initialStart === false) {
								appStore.initialStart = true;
								getSpotsSegments().then(segments => {
									console.log('Initial start with segments', segments);
									player.addSpotSegments(segments);
								});
							}
						}}
					/>
					
					{renderCurrentTime()}
				</div>
			</div>
		</React.Fragment>
	);
});

export const App = ({ appStore }) => {
	let polling = undefined;

	if (songsAPIUrl) {
		polling = {
			url: songsAPIUrl
		};
	}

	return (
		<Detector
			polling={polling}
			onChange={status => {
				appStore.isOnline = status;
				if (player) {
					player.queue.isOnline = status;
				}
			}}
			render={({ online }) => (
				<AppContent appStore={appStore} online={online} />
			)}
		/>
	);
};
