import moment from 'moment';

export function addItem(key, value, time = null) {
	if (time) {
		const { amount, unitOfTime } = time;
		const now = moment();
		const item = {
			value,
			expiry: now.add(amount, unitOfTime).valueOf()
		};
		localStorage.setItem(key, JSON.stringify(item));
		return;
	}
	localStorage.setItem(key, JSON.stringify({ value }));
}

export function fetchItem(key) {
	const json = localStorage.getItem(key);

	if (!json) {
		return null;
	}

	const item = JSON.parse(json);

	if (item.expiry) {
		const now = moment();
		if (now.valueOf() > item.expiry) {
			console.log('Removing', key);
			localStorage.removeItem(key);
			return null;
		}
	}

	return item.value;
}

export function deleteItem(key) {
	return localStorage.removeItem(key);
}
