import { observable, reaction } from 'mobx';

export const progressPreload = observable({
	songs: {}
});

reaction(
	() => progressPreload.songs,
	(songs, reaction) => reaction.dispose()
);

export const updateSong = (progressEvent, songId) => {
	if (progressPreload.songs[songId]) {
		const newObj = {...progressPreload.songs};
		newObj[songId] = calculatePorcent(
			progressEvent, 
			progressPreload.songs[songId]
		);
		progressPreload.songs = {...newObj};
	} else {
		addSong(progressEvent, songId);
	}
};

const addSong = (progressEvent, songId) => {
	const song = {
		songId: songId,
		progress: '0%',
		speed: 0,
		startTime: 0
	};
	const newSong = calculatePorcent(progressEvent, song);
	const newObj = {...progressPreload.songs};
	newObj[songId] = newSong;
	progressPreload.songs = {...newObj};
};

const calculatePorcent = (progressEvent, { songId, progress, speed, startTime }) => {
	if (startTime === 0) {
		startTime = Date.now();
	}
	const total = progressEvent.total;
	const loaded = progressEvent.loaded;
	const percentCompleted = Math.floor(loaded / total * 100);
	progress = percentCompleted + '%';
	const time = Date.now() - startTime;
	const chunk = percentCompleted * total;
	if (time > 0) {
		speed = ((chunk / 1024 / 1024) / (time / 100)).toFixed(2);
	}
	if (total === loaded) {
		return undefined;
	}
	return {
		songId, progress, speed, startTime
	};
};