import React from 'react';
import BlockUi from 'react-block-ui';

export const VersionInfo = ({ version, onClose }) => {
	return (
		<BlockUi tag='div' style={{position: 'absolute', width: '100%', height: '100%', zIndex: 1}}>
			<div className='update-message-content'>
				<h1>Versión {version}.</h1>
				<p>
					Software desarrollado por la empresa Qvox Tecnología Dinámica S.R.L.
					de C.V. en México.
				</p>
				<button className='form-button' onClick={onClose}>Cerrar</button>
			</div>
		</BlockUi>
	);
};
