import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';

export const CoreLogo = observer(({ id, src, width, height, style = {} }) => {
	const [className, setClassName] = useState('');

	useEffect(() => {
		let isMounted = true;
		if (className === 'show-logo') {
			setClassName('');
		}
		setImmediate(() => {
			if (isMounted) {
				setClassName('show-logo');
			}
		});
		return () => (isMounted = false);
		// eslint-disable-next-line
	}, []);

	return (
		<>
			<img
				style={style}
				className={`cover-logo ${className}`.trim()}
				id={id}
				src={src}
				alt='album-art-logo'
				width={width}
				height={height}
			/>
		</>
	);
});

export const Logo = React.memo(CoreLogo, (prevProps, nextProps) => {
	if (prevProps.src === nextProps.src) {
		return true;
	}
	return false;
});
