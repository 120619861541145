import React from 'react';

import { observer } from 'mobx-react';

export const ProgressBar = observer(({ progressBarStore, className = '' }) => {
	const styles = {
		height: '100%',
		width: progressBarStore.progress
	};

	/* if (parseInt(styles.width, 10) > 0) {
		styles.transition = 'width 1s linear';
	} */

	return (
		<div id='progress-bar' className={className}>
			<div id='progress-bar-content' style={styles}></div>
		</div>
	);
});
