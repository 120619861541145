import { Song } from './song';

const QUEUE_MAX = 5;

export class Queue {
	constructor({ isOnline }) {
		this.isOnline = isOnline;
		this.queue = [];
		this.pendingRequests = 0;
	}

	async forceAdd(songInfo) {
		const songsSize = this.queue.filter(i => i.isSpot === false).length;
		if (songsSize < QUEUE_MAX && this.pendingRequests < QUEUE_MAX) {
			this.pendingRequests++;
			const song = await (new Song()).initializeSong(songInfo);
			if (song) {
				this.queue.push(song);
			}
			this.pendingRequests--;
			return song;
		}
		return Promise.resolve();
	}

	forceAddToFirst(song) {
		this.queue.unshift(song);
	}
  
	addJukeBoxSong(song) {
		for (let i = 0, len = this.queue.length; i < len; i++) {
			const element = this.queue[i];
			if (!element.isSpot) {
				this.queue.splice(i, 0, song);
				break;
			}
		}
	}

	next() {
		if (this.queue.length === 0) {
			return null;
		}
		return this.queue.shift();
	}

	last() {
		if (this.queue.length === 0) {
			return null;
		}
		return this.queue[this.queue.length - 1];
	}

	isEmpty() {
		return this.queue.length === 0;
	}

	size() {
		return this.queue.length;
	}

	clear() {
		if (this.queue.length > 0) {
			this.queue.forEach(song => {
				song.pause();
				song = null;
			});
		}
		this.queue = [];
	}

	removeAllSpots() {
		this.queue = this.queue.filter(item => item.isSpot === false);
		console.log('RemoveAllSpots', this.queue);
	}

	removeAllSongs() {
		this.queue = this.queue.filter(item => item.isSpot === true || item.isJukebox === true);
		console.log('RemoveAllSongs', this.queue);
	}
}
