import axios from 'axios';

import { songsAPIUrl } from '../config';

export async function login(credentials) {
	const response = await axios({
		method: 'POST',
		url: `${songsAPIUrl}/api/v1/session/login`,
		data: credentials
	});

	return response.data.token;
}

export async function me(token) {
	const response = await axios({
		method: 'GET',
		url: `${songsAPIUrl}/api/v1/session/me`,
		headers: {
			'x-access-token': token
		}
	});

	return response.data;
}
