import React from 'react';

import { observer } from 'mobx-react';

export const DisclaimerLabel = observer(({ isOnline, isSlowConnection }) => {
	if (isOnline === true && isSlowConnection === false) {
		return null;
	}

	let text = '';

	if (isOnline === false) {
		text = 'Sin conexión a Internet.';
	} else if (isSlowConnection === true) {
		text = 'Conexión Inestable.';
	}

	if (!text) {
		return null;
	}

	return (
		<div
			style={{
				fontSize: '16px',
				color: '#ffffff',
				marginTop: 10,
				marginBottom: 5
			}}
		>
			<span
				className='glyphicon glyphicon-warning-sign'
				style={{ color: '#f0ad4e', marginRight: 10 }}
			/>
			<label>{text}</label>
		</div>
	);
});
