import { observable, reaction } from 'mobx';

import get from 'lodash/get';

import { fetchItem } from '../core/storage';

export const appStore = observable({
	isReady: false,
	isOnline: false,
	offLineState: false,
	wasPlaying: false,
	currentSong: null,
	sessionToken: !!fetchItem('APP_TOKEN_SESSION'),
	currentMusicChannel: null,
	sessionExpiredMessage: false,
	forceLogout: null,
	storeName: get(fetchItem('STORE_INFO'), 'storeName', ''),
	newUpdateAvailable: false,
	newUpdateDownloaded: false,
	initialStart: false,
	waitingForPlay: null,
	errorStarting: false,
	loadingJukeBox: false,
	deviceId: 'web'
});

reaction(
	() => appStore.isReady,
	(isReady, reaction) => reaction.dispose()
);
reaction(
	() => appStore.isOnline,
	(isOnline, reaction) => reaction.dispose()
);
reaction(
	() => appStore.offLineState,
	(offLineState, reaction) => reaction.dispose()
);
reaction(
	() => appStore.currentSong,
	(currentSong, reaction) => reaction.dispose()
);
reaction(
	() => appStore.isSlowConnection,
	(isSlowConnection, reaction) => reaction.dispose()
);
reaction(
	() => appStore.wasPlaying,
	(wasPlaying, reaction) => reaction.dispose()
);
reaction(
	() => appStore.sessionToken,
	(sessionToken, reaction) => reaction.dispose()
);
reaction(
	() => appStore.currentMusicChannel,
	(currentMusicChannel, reaction) => reaction.dispose()
);
reaction(
	() => appStore.sessionExpiredMessage,
	(sessionExpiredMessage, reaction) => reaction.dispose()
);
reaction(
	() => appStore.storeName,
	(storeName, reaction) => reaction.dispose()
);
reaction(
	() => appStore.newUpdateAvailable,
	(newUpdateAvailable, reaction) => reaction.dispose()
);
reaction(
	() => appStore.newUpdateDownloaded,
	(newUpdateDownloaded, reaction) => reaction.dispose()
);
reaction(
	() => appStore.initialStart,
	(initialStart, reaction) => reaction.dispose()
);
reaction(
	() => appStore.waitingForPlay,
	(waitingForPlay, reaction) => reaction.dispose()
);
reaction(
	() => appStore.errorStarting,
	(errorStarting, reaction) => reaction.dispose()
);
reaction(
	() => appStore.deviceId,
	(deviceId, reaction) => reaction.dispose()
);
reaction(
	() => appStore.loadingJukeBox,
	(loadingJukeBox, reaction) => reaction.dispose()
);
