import axios from 'axios';

import moment from 'moment';

import get from 'lodash/get';

import { fetchItem } from '../core/storage';

import { updateSong } from '../stores/preload.bar.store';

import { songsAPIUrl } from '../config';

export async function getSongInfo() {
	try {
		const storeId = get(fetchItem('STORE_INFO'), 'storeId', '');

		const token = fetchItem('APP_TOKEN_SESSION');

		const today = moment().format('YYYY-MM-DDTHH:mm');

		if (!storeId || !token) {
			console.log('No token or storeId provided');
			return;
		}

		const response = await axios({
			method: 'GET',
			url: `${songsAPIUrl}/api/v2/songs?storeId=${storeId}&date=${today}`,
			headers: {
				'x-access-token': token
			}
		});

		return response.data;
	} catch (err) {
		console.error('getSongInfo err:', err);
	}
}

export function startSong({ mediaId, isSpot, volume, mediaType }) {
	const storeId = get(fetchItem('STORE_INFO'), 'storeId', '');

	const token = fetchItem('APP_TOKEN_SESSION');

	if (!mediaId || !token) {
		console.log('No token or storeId provided');
		return;
	}

	return axios({
		method: 'POST',
		url: `${songsAPIUrl}/api/v2/songs/log`,
		headers: {
			'x-access-token': token
		},
		data: {
			storeId,
			volume,
			mediaId,
			mediaType,
			isSpot,
			clientDate: moment().format('YYYY-MM-DD HH:mm:ss')
		}
	}).catch(err => console.log(err));
}

export async function playerStatusTrack({ mediaId, isSpot, volume, status }) {
	const storeId = get(fetchItem('STORE_INFO'), 'storeId', '');

	const token = fetchItem('APP_TOKEN_SESSION');

	if (!mediaId || !token) {
		console.log('No token or storeId provided');
		return;
	}

	const response = await axios({
		method: 'POST',
		url: `${songsAPIUrl}/api/v2/songs/status`,
		headers: {
			'x-access-token': token
		},
		data: {
			storeId,
			volume,
			mediaId,
			isSpot,
			status,
			clientDate: mediaId ? moment().format('YYYY-MM-DD HH:mm:ss') : ''
		}
	}).catch(err => console.log(err));

	if (!response) return null;

	return response.data;
}

export async function getSongById(songId, isSpot = false) {
	try {
		const token = fetchItem('APP_TOKEN_SESSION');

		if (!token) {
			console.log('No token provided');
			return;
		}

		let url = `${songsAPIUrl}/api/v2/songs/${songId}?token=${token}`;

		if (isSpot) {
			url = `${songsAPIUrl}/api/v2/spots/${songId}?token=${token}`;
		}

		console.log('Consume song as buffer');

		const mimeType = 'audio/mp3';

		const options = {
			responseType: 'arraybuffer',
			headers: {
				'Content-Type': mimeType
			},
			onDownloadProgress: progressEvent => {
				updateSong(progressEvent, songId);
			}
		};

		const response = await axios.get(url, options);
		console.log('------ response ------');
		console.log(response);
		const blob = new Blob([response.data], {
			type: mimeType
		});
		console.log('------ blob --------');
		console.log(blob);
		return URL.createObjectURL(blob);
	} catch (err) {
		console.error('Error :: getSongById', err);
	}
}

export function getSongCoverById(songId, isSpot = false) {
	const token = fetchItem('APP_TOKEN_SESSION');
	if (!token) {
		console.log('No token provided');
		return;
	}
	return `${songsAPIUrl}/api/v2/${
		isSpot ? 'spots' : 'songs'
	}/cover/${songId}?token=${token}`;
}

export async function getSpotsSegments() {
	const storeId = get(fetchItem('STORE_INFO'), 'storeId', '');

	const token = fetchItem('APP_TOKEN_SESSION');

	if (!storeId || !token) {
		console.log('No token or storeId provided');
		return;
	}

	const response = await axios({
		method: 'GET',
		url: `${songsAPIUrl}/api/v2/spots?storeId=${storeId}`,
		headers: {
			'x-access-token': token
		}
	});

	return response.data;
}
