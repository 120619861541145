import { observable, reaction } from 'mobx';

export const playBarStore = observable({
	isPlaying: false,
	currentTime: '00:00 / 00:00'
});

reaction(
	() => playBarStore.isPlaying,
	(isPlaying, reaction) => reaction.dispose()
);
