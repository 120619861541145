import React from 'react';

import Lottie from 'react-lottie';

import * as animationData from './1424-no-connection.json';

const defaultOptions = {
	loop: true,
	autoplay: true,
	animationData: animationData.default
};

export const OfflineSplashScreen = () => {
	return (
		<div className='offline-content-screen'>
			<Lottie
				isClickToPauseDisabled
				options={defaultOptions}
				height='50%'
				width='50%'
			/>
			<label className='looking-connection-label'>Buscando conexión...</label>
		</div>
	);
};
