import React from 'react';

import get from 'lodash/get';

import { observer } from 'mobx-react';

import { AudioSpectrum } from '../AudioSpectrum';

import logo from '../../img/logo.png';

import { Logo } from '../Logo';

import { DefaultLogo } from '../Logo/default';

const AlbumContent = ({ isPlaying, isSpot, songTitle, artistName, album }) => {
	if (isSpot === true) {
		return null;
	}
	const defaultTitle = '¡BIENVENIDO!';
	const defaultInstructions =
		'Haz click en el botón de abajo para iniciar tu canal.';
	const title = isPlaying ? songTitle : defaultTitle;
	const moreInfo = isPlaying ? artistName : defaultInstructions;
	return (
		<div>
			<span className='song-title'>{title}</span>
			<div className='more-info'>
				<span>{moreInfo}</span>
				<br />
				<span className='album-info'>{album}</span>
			</div>
		</div>
	);
};

const StoreName = ({ isPlaying, storeName, isSpot }) => {
	if (isSpot === true || !isPlaying) {
		return null;
	}
	return (
		<div className='store-title'>
			<span>{storeName}</span>
		</div>
	);
};

export const AlbumInfo = observer(
	({ storeName, songInfo, isPlaying, waitingForPlay, loadingJukeBox }) => {
		const songTitle = get(songInfo, 'songTitle', '');
		const artistName = get(songInfo, 'artistName', '');
		const album = get(songInfo, 'album', '');
		const src = get(songInfo, 'image', null);
		const isSpot = get(songInfo, 'isSpot', null);

		const renderLogo = () => {
			if (!songInfo || loadingJukeBox) {
				return <DefaultLogo src={logo} />;
			}
			if (isSpot || waitingForPlay) {
				return <DefaultLogo className={isSpot ? 'logo-spot' : ''} src={src} />;
			}
			return <Logo id='album-cover' src={src} width='200' height='200' />;
		};

		return (
			<>
				<div className='content-player-box'>
					<StoreName
						storeName={storeName}
						isPlaying={isPlaying}
						isSpot={isSpot}
					/>
					<div className='cover-container'>{renderLogo()}</div>
					<AlbumContent
						isPlaying={isPlaying}
						waitingForPlay={waitingForPlay}
						loadingJukeBox={loadingJukeBox}
						isSpot={isSpot}
						songTitle={songTitle}
						artistName={artistName}
						album={album}
					/>
					<div style={{ margin: 0 }}>
						{<AudioSpectrum isPlaying={isPlaying} isSpot={isSpot} />}
					</div>
				</div>
			</>
		);
	}
);
