export async function sleep(time) {
	return new Promise((resolve, reject) => {
		setTimeout(() => resolve(), time);
	});
}
export function getBase64Image(url) {
	return new Promise((resolve, reject) => {
		const img = new Image();
		img.setAttribute('crossorigin', 'anonymous');

		img.onload = () => {
			const canvas = document.createElement('canvas');
			const ctx = canvas.getContext('2d');
			canvas.height = img.naturalHeight;
			canvas.width = img.naturalWidth;
			ctx.drawImage(img, 0, 0);
			const base64 = canvas.toDataURL('image/png');
			return resolve(base64);
		};

		img.onerror = err => {
			console.log('Error Image loaded', url, err);
			return reject(err);
		};

		img.src = url;
	});
}

export const detectIE = () => {
	const ua = window.navigator.userAgent;
	const msie = ua.indexOf('MSIE ');
	if (msie > 0) {
		return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
	}

	const trident = ua.indexOf('Trident/');
	if (trident > 0) {
		const rv = ua.indexOf('rv:');
		return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
	}

	const edge = ua.indexOf('Edge/');
	if (edge > 0) {
		return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
	}

	return false;
};
