import React, { useEffect } from 'react';

import Lottie from 'react-lottie';

import * as animationData from './2881-music-fly.json';
import { observer } from 'mobx-react';
import { RetryButton } from '../RetryButton';
import { ProgressBar } from '../ProgressBar';
import { progressPreload } from '../../stores/preload.bar.store';
import { toJS } from 'mobx';

const defaultOptions = {
	loop: true,
	autoplay: true,
	animationData: animationData.default
};

let last = [];

export const SplashScreen = observer(
	({ errorStarting = false, onRetry = () => {} }) => {
		useEffect(() => {
			document.getElementsByTagName('body')[0].style.overflowY = 'hidden';
			return () => {
				document.getElementsByTagName('body')[0].style.overflowY = 'auto';
			};
		}, []);
		let message = 'Buscando canciones';
		if (errorStarting) {
			message = 'Ha ocurrido un error no esperado';
		}

		const customProgress = { progress : '0%', speed: 0 };
		useEffect(() => {
			let objSongs = toJS(progressPreload.songs);
			const m = Object.keys(objSongs).map(a => objSongs[a]);
      last = m.sort((a, b) => a.startTime - b.startTime);
      // eslint-disable-next-line
		}, [progressPreload.songs]);

		return ( 
			<div className='lottie-animation-content'>
				<Lottie
					isClickToPauseDisabled
					options={defaultOptions}
					height='100%'
					width='100%'
				/>
				<label className='looking-songs-label'>{message}</label>
				
				{!errorStarting && <>
					<ProgressBar 
						progressBarStore={last[0] ? last[0] : customProgress} 
						className='remove-absolute'
					/>
					<div className='showPreload'>
						{last[0] ? last[0].progress : customProgress.progress} - 
						{last[0] ? last[0].speed : customProgress.speed} Mb/seg
					</div>
				</>}
				{errorStarting && <RetryButton onRetry={onRetry} />}
			</div>
		);
	}
);
