import React from 'react';

import Lottie from 'react-lottie';

import * as animationData from './4031-voice-recognition.json';

const defaultOptions = {
	loop: true,
	autoplay: true,
	animationData: animationData.default
};

export const AudioSpectrum = ({ isPlaying, isSpot }) => {
	if (!isPlaying || isSpot === true || isSpot === null) {
		return null;
	}
	return (
		<div className='song-spectrum' style={{ backgroundColor: 'transparent', marginTop: 0, width: 210 }}>
			<Lottie
				isClickToPauseDisabled
				options={defaultOptions}
				height='50%'
				width='50%'
			/>
		</div>
	);
};
