import { getSongById, getSongCoverById, startSong } from './player.service';

import * as jukeboxService from './jukebox.service';

import { getBase64Image } from '../core/helpers';
import { AudioSingleton } from './AudioSingleton';

export const SONG_STATES = {
	READY: 0,
	PLAYING: 1,
	PAUSE: 2,
	ENDED: 3,
	ERROR: 4
};

export const getSongStateDescription = value => {
	return Object.keys(SONG_STATES).find(key => SONG_STATES[key] === value);
};

let currentStoreSpotImage = null;

AudioSingleton.bindEvents();

export class Song {
	constructor() {
		this.audio = AudioSingleton.getInstance();
		this.almostFinished = null;
		this.onTimeUpdate = this.onTimeUpdate.bind(this);
		this.onEnd = this.onEnd.bind(this);
		this.onError = this.onError.bind(this);
		this.onPause = this.onPause.bind(this);
		this.onPlay = this.onPlay.bind(this);
	}

	async initializeSong(songInfo) {
		try {
			if (!songInfo) return;
			const {
				songId,
				songTitle,
				artistName,
				album,
				genre,
				time,
				musicChannel,
				isSpot = false,
				isJukebox = false
			} = songInfo;
			this.isSpot = isSpot;
			this.isJukebox = isJukebox;
			this.songId = songId;
			this.musicChannel = musicChannel;
			this.songTitle = songTitle;
			this.artistName = artistName;
			this.album = album;
			this.genre = genre;
			const [src, image] = await Promise.all([
				this.loadAudio(songId, isSpot),
				this.createImage(getSongCoverById(songId, isSpot))
			]);
			this.src = src;
			this.image = image;
			this.time = time;
			this.state = SONG_STATES.READY;
			this.currentSongSecond = 0;
			this.percentageOfTheSong = 0;
			return this;
		} catch (err) {
			console.log('Error: initializeSong', err);
		}
	}

	async loadAudio(songId, isSpot) {
		return new Promise((resolve, reject) => {
			getSongById(songId, isSpot).then(url => {
				if (!url) {
					const message = 'Cannot generate the url to get the audio';
					console.log(message);
					return reject(message);
				}
				const audio = new Audio(url);
				let timeout = setTimeout(() => {
					console.log('Song media audio is ready to play [timeout]');
					if (timeout) {
						clearTimeout(timeout);
						timeout = null;
					}
					return resolve(url);
				}, 15 * 1000);
				audio.addEventListener(
					'loadedmetadata',
					() => {
						console.log('Song media audio is ready to play [loadedmetadata]');
						if (timeout) {
							clearTimeout(timeout);
							timeout = null;
						}
						return resolve(url);
					},
					false
				);
			});
		});
	}

	createImage(url) {
		if (this.isSpot && currentStoreSpotImage) {
			return Promise.resolve(currentStoreSpotImage);
		}
		if (this.isSpot) {
			currentStoreSpotImage = this.image;
		}
		return getBase64Image(url);
	}

	play() {
		this.signal = 'play';
		this.state = SONG_STATES.PLAYING;
		this.audio.src = this.src;
		startSong({
			mediaId: this.songId,
			volume: this.audio.volume,
			isSpot: this.isSpot,
			mediaType: this.type
		});

		console.log('Starting playing media: ', {
			mediaId: this.songId,
			volume: this.audio.volume,
			isSpot: this.isSpot,
			mediaType: this.type
		});
		jukeboxService.startSong(this.songId, this.type);
		//console.log(`Playing song in channel ${this.musicChannel}`);
		const playPromise = this.audio.play();
		return playPromise ? playPromise.catch(this.onError) : playPromise;
	}

	get volume() {
		return this.audio.volume;
	}
  
	get type() {
		let type = 'm';

		if (this.isSpot) {
			type = 's';
		} else if (this.isJukebox) {
			type = 'y';
		}
    
		return type;
	}

	pause() {
		this.signal = 'pause';
		if (
			!this.audio ||
			this.state === SONG_STATES.PAUSE ||
			this.state === SONG_STATES.ENDED
		) {
			return;
		}
		this.audio.pause();
		this.state = SONG_STATES.PAUSE;
	}

	onPause() {
		this.state = SONG_STATES.PAUSE;
	}

	onTimeUpdate() {
		const songDuration = Math.ceil(this.audio.duration);

		this.currentSongSecond = Math.floor(this.audio.currentTime);
    
		if (
			(songDuration - this.currentSongSecond) === 10 && 
      !this.isSpot && 
      this.almostFinished === null
		) {
			this.almostFinished = true;
		}

		this.percentageOfTheSong = (this.currentSongSecond * 100) / songDuration;
	}

	onEnd() {
		this.state = SONG_STATES.ENDED;
	}

	onError(e) {
		this.state = SONG_STATES.ERROR;
		console.log('Song Error', e);
	}

	onPlay() {
		const isUserSignal = this.signal === 'play';
		console.log('Is User Signal', isUserSignal);
		if (!isUserSignal) {
			this.audio.pause();
		}
		this.handleOnPlay && this.handleOnPlay(isUserSignal);
	}
}
