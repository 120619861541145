let isDev = false;
let computerId = '';

try {
	const electron = window.require('electron');

	const config = electron.remote.getGlobal('config');

	isDev = config.isDev;
	computerId = config.computerId;
} catch (err) {
	isDev = process.env.REACT_APP_ENV === 'DEV';
}

export const songsAPIUrl = isDev
	? 'http://localhost:4800'
	: 'https://playermiddleware.voxpopaudio.com';

export function getComputerId() {
	return computerId;
}

export function getTrackingThreshold() {
	return 1000 * 60 * 5;
}
