import React from 'react';
import { observer } from 'mobx-react';

export const PlayButton = observer(
	({ onClick, visible, isSpot, disabled = false, isPlaying = false }) => {
		if (!visible) {
			return null;
		}

		const style = {
			marginLeft: -2
		};

		if (!isPlaying && !isSpot) {
			style.marginLeft = 5;
		}

		const customProps = {
			iconState: !isPlaying ? 'glyphicon-play' : 'glyphicon-stop',
			containerClass: '',
			buttonClass: ''
		};

		if (isSpot) {
			customProps.containerClass = ' button-container-disabled';
			customProps.buttonClass = ' button-disabled';
			customProps.iconState = 'glyphicon-stop';
		}

		if (disabled) {
			customProps.containerClass = ' button-container-disabled';
			customProps.buttonClass = ' button-disabled';
		}

		return (
			<div className={`play-button-container${customProps.containerClass}`}>
				<span
					onClick={onClick}
					style={style}
					className={`play-button${customProps.buttonClass} glyphicon ${customProps.iconState}`}
				></span>
			</div>
		);
	}
);
