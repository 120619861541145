import moment from 'moment';

import get from 'lodash/get';

import { Queue } from './queue';

import { SONG_STATES, getSongStateDescription, Song } from './song';

import { fetchItem } from './storage';

import { getSpotsSegments, getSongInfo } from './player.service';

import * as jukeboxService from './jukebox.service';

import { AudioSingleton } from './AudioSingleton';

const today = moment().startOf('day');

let timeoutId = null;

let lastSong = null;

const silence = new Audio(
	`https://playermiddleware.voxpopaudio.com/dist/silence.mp3?t=${new Date().getTime()}`
);
silence.loop = true;

export class Player {
	constructor({
		appStore,
		progressBarStore,
		playBarStore,
		initialQueueSize = 1
	}) {
		this.initialQueueSize = initialQueueSize;
		this.appStore = appStore;
		this.playBarStore = playBarStore;
		this.progressBarStore = progressBarStore;
		this.queue = new Queue({
			isOnline: this.appStore.isOnline
		});
		this.spotSegments = null;
		this.paused = true;
		this.currentSong = null;
		this.repaint = this.repaint.bind(this);
	}

	addSpotSegments(segments) {
		this.spotSegments = segments;
	}

	getCurrentStatus() {
		let status = 'UNKNOWN';
		let mediaId = '';
		let volume = '';
		let isSpot = false;
		if (this.currentSong) {
			mediaId = this.currentSong.songId;
			volume = this.currentSong.volume;
			isSpot = this.currentSong.isSpot;
			status = getSongStateDescription(this.currentSong.state);
		} else if (this.queue.isEmpty()) {
			status = 'LOADING';
		} else if (this.playBarStore.isPlaying === false) {
			status = 'STOPPED';
		}
		return {
			mediaId,
			volume,
			isSpot,
			status
		};
	}

	start() {
		return this.initQueue().then(songs => {
			if (songs && songs.length > 0) {
				if (songs[0]) {
					this.appStore.currentMusicChannel = songs[0].musicChannel;
				}
				if (!timeoutId) {
					this.repaint();
				}
				this.appStore.errorStarting = false;
			}
		});
	}

	async play(forcePause = false, autoPlay = false) {
		if (!fetchItem('APP_TOKEN_SESSION')) {
			this.logout();
			return false;
		}
		if (this.appStore.initialStart) {
			silence.play();
		}
		this.appStore.waitingForPlay = true;
		if (this.appStore.isOnline) {
			let jukeboxInfo = null;
			if ((this.currentSong === null || this.currentSong.state === SONG_STATES.ENDED)) {
				const type = get(lastSong, 'type', 'm');
				console.log('Ending media... Media_type:', type);
				jukeboxInfo = await jukeboxService.endSong(
					get(lastSong, 'songId', ''),
					type
				);
				//console.log('Yumix API Response:' + JSON.stringify(jukeboxInfo));
			}
			if (jukeboxInfo && jukeboxInfo.response === true) {
				console.log('Yumix Song Catched');
				this.appStore.loadingJukeBox = true;
				const jSong = new Song();
				await jSong.initializeSong(jukeboxInfo);
				this.queue.addJukeBoxSong(jSong);
			}
      
			const info = await getSongInfo();
			if (info) {
				if (info.musicChannel && info.musicChannel !== this.appStore.currentMusicChannel) {
					console.log(
						`Channel change received from ${this.appStore.currentMusicChannel} to ${info.musicChannel}`
					);
					this.appStore.currentMusicChannel = info.musicChannel;
					this.queue.removeAllSongs();
				}

				console.log('Spots change request received? ', info.shouldUpdateSpots);

				if (info.shouldUpdateSpots) {
					const segments = await getSpotsSegments();
					console.log('Spots new list received: ', segments);
					this.queue.removeAllSpots();
					this.addSpotSegments(segments);
				}
				this.queue.forceAdd(info);
			} else if (this.queue.isEmpty()) {
				this.appStore.errorStarting = true;
			}
		}

		if (this.currentSong === null || autoPlay === true) {
			this.currentSong = this.queue.next();
			AudioSingleton.setSong(this.currentSong);
		}

		if (autoPlay && this.appStore.errorStarting === true) {
			this.currentSong = null;
			this.appStore.currentSong = null;
		}

		// if is still null just skip the instruction
		if (this.currentSong === null) {
			return false;
		}

		if (forcePause || this.currentSong.state === SONG_STATES.PLAYING) {
			this.currentSong.pause();
			this.appStore.wasPlaying = false;
			this.appStore.waitingForPlay = false;
			if (!autoPlay) {
				this.currentSong = null;
				this.appStore.currentSong = null;
				this.playBarStore.currentTime = '00:00 / 00:00';
				this.progressBarStore.progress = 0;
			}
			return false;
		}
		this.currentSong.handleOnPlay = async isUserSignal => {
			if (isUserSignal === false) {
				const isPlaying = await this.play();
				this.playBarStore.isPlaying = isPlaying;
			}
		};
		this.currentSong.play();
		lastSong = this.currentSong;
		this.appStore.waitingForPlay = false;
		if (this.appStore.loadingJukeBox === true) {
			this.appStore.loadingJukeBox = false;
		}
		return true;
	}

	async initQueue() {
		const songs = [];
		try {
			for (let i = 0; i < this.initialQueueSize; i++) {
				let song = await getSongInfo();
				if (!song) {
					throw new Error('No song info available');
				}
				song = await this.queue.forceAdd(song);
				songs.push(song);
			}
			const type = get(lastSong, 'type', 'm')
			const jukeboxInfo = await jukeboxService.endSong(
				get(lastSong, 'songId', ''),
				type
			)
			if (jukeboxInfo && jukeboxInfo.response === true ) {
				const jSong = new Song()
				await jSong.initializeSong(jukeboxInfo)
				this.queue.addJukeBoxSong(jSong)
				songs.unshift(jSong)
			}
		} catch (err) {
			this.appStore.errorStarting = true;
			return null;
		}
		return songs;
	}

	logout() {
		console.log('logout');
		if (timeoutId) {
			clearTimeout(timeoutId);
			timeoutId = null;
		}
		this.appStore.isReady = false;
		this.appStore.errorStarting = false;
		this.appStore.sessionToken = null;
		this.appStore.sessionExpiredMessage = true;
		this.queue.clear();
		if (this.currentSong) {
			this.currentSong.pause();
			this.currentSong = null;
			this.appStore.currentSong = null;
		}
		this.playBarStore.isPlaying = false;
		this.playBarStore.currentTime = '00:00 / 00:00';
		this.progressBarStore.progress = 0;
		this.appStore.wasPlaying = false;
		this.appStore.initialStart = false;
		silence.pause();
	}

	async repaint() {
		const isAlreadyPlaying =
			this.currentSong && this.currentSong.state === SONG_STATES.PLAYING;

		if (!isAlreadyPlaying) {
			this.appStore.isReady = this.queue.isEmpty() === false;
			if (this.appStore.isReady) {
				// autoplay if before was playing
				if (this.appStore.wasPlaying && this.playBarStore.isPlaying === false) {
					this.appStore.wasPlaying = false;
					const isPlaying = await this.play();
					this.playBarStore.isPlaying = isPlaying;
				}
			}
		}

		if (this.appStore.offLineState && this.appStore.isOnline) {
			console.log('Backing from the dead!');
			getSpotsSegments().then(segments => {
				console.log('Should Update Spots Backing from the dead!', segments);
				this.addSpotSegments(segments);
				this.initQueue().then(() => {
					console.log('Queue is ready to use!');
				});
			});
		}

		this.appStore.offLineState =
			!isAlreadyPlaying && this.queue.isEmpty() && !this.appStore.isOnline;

		const connection =
			navigator.connection ||
			navigator.mozConnection ||
			navigator.webkitConnection;

		if (connection) {
			this.appStore.isSlowConnection = /\slow-2g|2g|3g/.test(
				connection.effectiveType
			);
		}

		const hasToken = fetchItem('APP_TOKEN_SESSION');

		const shouldLogout =
			(!hasToken && !isAlreadyPlaying) ||
			(!hasToken && this.appStore.forceLogout === true);

		if (shouldLogout) {
			this.logout();
			return;
		}

		if (this.spotSegments && isAlreadyPlaying) {
			const rightNow = moment().format('HH:mm');

			const spots = this.spotSegments[rightNow];

			if (spots) {
				delete this.spotSegments[rightNow];
				console.log(`SpotSegment: ${rightNow}`, spots);
				Promise.all(
					spots.map(id => {
						const spot = new Song();
						return spot.initializeSong({
							songId: id,
							isSpot: true
						});
					})
				).then(spots => {
					console.log('Adding Segment of Spots', spots.length);
					spots.reverse().forEach(spot => {
						this.queue.forceAddToFirst(spot);
					});
				});
			}
		}

		if (this.currentSong) {
			if (
				!this.appStore.currentSong ||
				this.appStore.currentSong.songId !== this.currentSong.songId
			) {
				this.appStore.currentSong = this.currentSong;
			}
			if (this.currentSong.state === SONG_STATES.PLAYING) {
				this.progressBarStore.progress = `${this.currentSong.percentageOfTheSong}%`;

				const elapsedTime = today
					.clone()
					.add(this.currentSong.currentSongSecond, 'seconds')
					.format('mm:ss');

				let songTime = get(this.currentSong, 'time', '00:00');

				if (this.currentSong.isSpot) {
					songTime = moment()
						.startOf('day')
						.add(Math.floor(this.currentSong.audio.duration), 'seconds')
						.format('mm:ss');
				}

				this.playBarStore.currentTime = `${elapsedTime} / ${songTime}`;
			} else if (
				this.currentSong.state === SONG_STATES.ENDED ||
				this.currentSong.state === SONG_STATES.ERROR
			) {
				console.log('Song end or has errors', this.currentSong.state);
				if (this.currentSong.isSpot) {
					this.appStore.currentSong = {
						image: this.currentSong.image,
						isSpot: this.currentSong.isSpot
					};
				}
				this.playBarStore.isPlaying = false;
				this.playBarStore.currentTime = '00:00 / 00:00';
				this.progressBarStore.progress = 0;
				if (fetchItem('APP_TOKEN_SESSION')) {
					this.appStore.wasPlaying = true;
					this.playBarStore.isPlaying = await this.play(false, true);
				}
			} else if (this.currentSong.state === SONG_STATES.PAUSE) {
				if (this.signal !== 'pause') {
					const isPlaying = await this.play(true);
					this.playBarStore.isPlaying = isPlaying;
				}
			}
		}
		// console.log(
		// 	`Queue size ${this.queue.size()} pending: ${this.queue.pendingRequests}`
		// );
		timeoutId = setTimeout(async () => {
			await this.repaint();
		}, 1000 / 60);
	}
}
