import React, { useEffect, useState } from 'react';

import moment from 'moment';

import get from 'lodash/get';

import { observer } from 'mobx-react';

import { useToasts } from 'react-toast-notifications';

import { Animated } from 'react-animated-css';

import { login, me } from '../../core/login.service';

import { addItem } from '../../core/storage';

import { getComputerId } from '../../config';

import { VersionInfo } from '../VersionInfo';

import packageJson from '../../../package.json';

const MAX_PIN_LENGTH = 6;

export const LoginForm = observer(({ appStore }) => {
	const { addToast, removeAllToasts } = useToasts();
	const [pin, setPin] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [showVersionInfo, setShowVersionInfo] = useState(false);

	useEffect(() => {
		if (appStore.sessionExpiredMessage === true && !appStore.forceLogout) {
			const timeOutId = setTimeout(() => {
				addToast('Su sesión ha expirado.', {
					appearance: 'warning',
					autoDismiss: true,
					placement: 'top-center'
				});
				appStore.sessionExpiredMessage = false;
				clearTimeout(timeOutId);
			}, 750);
		}
		// eslint-disable-next-line
	}, [appStore.sessionExpiredMessage]);

	const handleOnPinChange = evt => {
		const value = evt.target.value;
		if (/^[0-9\b]+$/.test(value) || value === '') {
			setPin(value);
		}
	};

	const handleOnEmailChange = evt => {
		setEmail(evt.target.value);
	};

	const handleOnPasswordChange = evt => {
		setPassword(evt.target.value);
	};

	const handleSubmit = async e => {
		e.preventDefault();
		removeAllToasts();
		try {
			if (pin.length < MAX_PIN_LENGTH || isNaN(Number(pin))) {
				addToast('El pin no es válido.', {
					appearance: 'error',
					autoDismiss: true,
					placement: 'top-center'
				});
				return;
			}
			console.log('Computer ID: ', getComputerId());

			appStore.newUpdateAvailable = false;
			appStore.newUpdateDownloaded = false;

			const token = await login({
				email,
				password,
				storePin: pin,
				deviceId: appStore.deviceId
			});

			const user = await me(token);

			appStore.sessionToken = token;
			appStore.storeName = user.storeName;
			appStore.forceLogout = null;
			appStore.errorStarting = false;

			let expireAt = get(user, 'session.expireAt', null);
			let secondsToExpire = null;
			let expirationInfo = null;
			console.log('**expireAt**', expireAt);
			if (expireAt) {
				expireAt = moment(new Date(user.session.expireAt * 1000));

				secondsToExpire = Math.ceil(
					moment.duration(expireAt.diff(moment())).asSeconds()
				);

				expirationInfo = {
					amount: secondsToExpire,
					unitOfTime: 'seconds'
				};
			}
			addItem('STORE_INFO', user, expirationInfo);
			addItem('APP_TOKEN_SESSION', appStore.sessionToken, expirationInfo);
		} catch (err) {
			const statusCode = get(err, 'response.status', null);
			const message = get(err, 'message', null);
			console.log('**LOGIN ERROR**', message);
			if (message === 'Network Error') {
				addToast('El servicio no está disponible por el momento.', {
					appearance: 'error',
					autoDismiss: true,
					placement: 'top-center'
				});
				return;
			}
			if (statusCode === 401) {
				addToast('Su email, contraseña o pin es incorrecto.', {
					appearance: 'error',
					autoDismiss: true,
					placement: 'top-center'
				});
				return;
			}
			addToast('El pin no es válido.', {
				appearance: 'error',
				autoDismiss: true,
				placement: 'top-center'
			});
		}
	};
	return (
		<>
			{showVersionInfo && (
				<VersionInfo
					version={packageJson.version}
					onClose={() => setShowVersionInfo(false)}
				/>
			)}
			<Animated
				style={{ paddingTop: 20 }}
				animationIn='bounceInDown'
				animationInDuration={1400}
				isVisible
			>
				<div className='login-border-container'>
					<div className='login-page'>
						<div className='login-header'>
							<div className='icon-section'>
								<span className='glyphicon glyphicon-log-in'></span>
							</div>
							<div className='text-section'>
								<p>Iniciar sesión</p>
							</div>
						</div>
						<div className='form'>
							<form className='login-form' onSubmit={handleSubmit}>
								<input
									name='email'
									value={email}
									type='email'
									placeholder='Email'
									onChange={handleOnEmailChange}
									required
								/>
								<input
									name='password'
									value={password}
									type='password'
									placeholder='Contraseña'
									onChange={handleOnPasswordChange}
									required
								/>
								<input
									name='pin'
									value={pin}
									type='text'
									placeholder='PIN de 6 dígitos'
									onChange={handleOnPinChange}
									maxLength={MAX_PIN_LENGTH}
									required
								/>
								<button className='form-button'>ENTRAR</button>
							</form>
							<p
								className='version-data'
								onClick={() => setShowVersionInfo(true)}
							>
								Versión {packageJson.version}
							</p>
						</div>
					</div>
				</div>
			</Animated>
		</>
	);
});
